<template>
  <div>
    <div class="title">
      <div>
        <span style="margin-right:20px">市场</span>
        <el-select
          v-model="ruleForm.value1"
          clearable
          placeholder="请选择经营户"
        >
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="getSeller_ByMarketId"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:20px">商户</span>
        <el-select
          v-model="ruleForm.value2"
          clearable
          placeholder="请选择经营户"
        >
          <el-option
            v-for="item in options2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="primary">查询</el-button>
        <el-button type="primary" @click="centerDialog">添加</el-button>
        <el-button>导入数据</el-button>
        <el-button>导出数据</el-button>
        <el-button>模板下载</el-button>
      </div>
    </div>
    <el-table
      :data="tableData.data"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      style="width: 100%;margin-top:20px"
      border
    >
      <el-table-column prop="id" label="分类ID"> </el-table-column>
      <el-table-column prop="category_name" label="所属分类"> </el-table-column>
      <el-table-column prop="name" label="分类名称"> </el-table-column>
      <el-table-column prop="market_name" label="所属市场"> </el-table-column>
      <el-table-column prop="seller_name" label="所属商户"> </el-table-column>
      <el-table-column prop="seller_id" label="商户ID"> </el-table-column>
      <el-table-column prop="shopCount" label="分类下商品数量">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <!-- 弹框 -->
    <el-dialog
      :title="title"
      @close="close"
      :visible.sync="centerDialogVisible"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="市场" prop="value1">
          <el-select v-model="ruleForm.value1" clearable placeholder="请选市场">
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.comp_name"
              :value="item.id"
              @click.native="getSeller_ByMarketId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户" prop="value2">
          <el-select
            v-model="ruleForm.value2"
            clearable
            placeholder="请选择经营户"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="GET_ShopBySellerId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺" prop="value3">
          <el-select
            v-model="ruleForm.value3"
            clearable
            placeholder="请选择店铺"
          >
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所属类目" prop="category_id">
          <el-cascader
            :options="CategoryList"
            :props="{ value: 'id', label: 'name', checkStrictly: true }"
            clearable
            v-model="category"
          ></el-cascader>
        </el-form-item> -->
        <el-form-item label="商品名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            style="width:217px"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit('ruleForm')">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getmarket } from '../../../api/layout'
import {
  GETproductsellertypes,
  getCategoryList,
  POSTproductsellertypes,
  getSellerByMarketId,
  GETShopBySellerId,
  PUTproductsellertypes
} from '../../../api/productList'
export default {
  name: 'commodityLists',
  data () {
    return {
      options1: '', // 头部市场
      options2: '', // 头部市场
      options3: '', // 店铺
      centerDialogVisible: false,
      title: '添加分类',
      id: '',
      page: 1,
      limit: 10,
      tableData: '', // 表格数据
      CategoryList: [], // 所属类目数据
      // category: [], // 所属类目ID
      ruleForm: {
        // category_id: '', // 所属类目ID
        name: '', // 商品名称
        value1: '', // 市场
        value2: '', // 商户
        value3: '' // 店铺
      },
      rules: {
        // category_id: [
        //   { required: true, message: '请选择所属类目', trigger: 'blur' }
        // ],
        name: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],
        value1: [{ required: true, message: '请选择市场', trigger: 'blur' }],
        value2: [{ required: true, message: '请选择商户', trigger: 'blur' }],
        value3: [{ required: true, message: '请选择店铺', trigger: 'blur' }]
      }
    }
  },
  watch: {
    // category (val) {
    //   this.ruleForm.category_id = val[val.length - 1]
    // }
  },
  methods: {
    // 头部市场
    get_market () {
      getmarket().then(res => {
        if (res.code === 200) {
          this.options1 = res.data
        }
      })
    },
    // 选择啦市场
    getSeller_ByMarketId () {
      getSellerByMarketId(this.ruleForm.value1).then(res => {
        this.options2 = res.data
      })
      this.ruleForm.value2 = ''
      this.ruleForm.value3 = ''
    },
    // 选择啦商户
    GET_ShopBySellerId () {
      GETShopBySellerId(this.ruleForm.value2).then(res => {
        if (res.code === 200) {
          this.options3 = res.data
        }
      })
      this.ruleForm.value3 = ''
    },
    // 表格数据
    product_seller_types ({ page = this.page, limit = this.limit } = {}) {
      GETproductsellertypes({ page, limit }).then(res => {
        this.tableData = res.data
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.product_seller_types()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.product_seller_types()
    },
    //  添加按钮
    centerDialog () {
      this.title = '添加分类'
      this.centerDialogVisible = true
      getCategoryList().then(res => {
        this.CategoryList = res.data
      })
    },
    // 弹框关闭
    close () {
      this.category = []
      this.ruleForm.category_id = ''
      this.ruleForm.name = ''
      this.ruleForm.value3 = ''
      this.ruleForm.value2 = ''
      this.ruleForm.value1 = ''
      this.id = ''
      this.options2 = ''
      this.options3 = ''
      this.CategoryList = []
    },
    // 确认按钮
    submit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // const categoryid = this.ruleForm.category_id[
          //   this.ruleForm.category_id.length - 1
          // ]
          const data = {
            // category_id: categoryid,
            name: this.ruleForm.name,
            seller_id: this.ruleForm.value2,
            market_id: this.ruleForm.value1,
            shop_id: this.ruleForm.value3
          }
          if (this.title === '添加分类') {
            POSTproductsellertypes(data).then(res => {
              if (res.code === 200) {
                this.centerDialogVisible = false
                this.$message.success(`${res.data}`)
                this.product_seller_types({
                  page: this.page,
                  limit: this.limit
                })
              }
            })
          } else {
            PUTproductsellertypes(data, this.id).then(res => {
              if (res.code === 200) {
                this.centerDialogVisible = false
                this.$message.success(`${res.data}`)
                this.id = ''
                this.product_seller_types({
                  page: this.page,
                  limit: this.limit
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // 编辑按钮
    async handleEdit (row) {
      getCategoryList().then(res => {
        this.CategoryList = res.data
      })
      this.title = '编辑分类'
      this.centerDialogVisible = true
      // this.ruleForm.category_id = row.category_id
      this.id = row.id
      this.ruleForm.value1 = row.market_id
      await this.getSeller_ByMarketId()
      this.ruleForm.value2 = row.seller_id
      await this.GET_ShopBySellerId()
      this.ruleForm.value3 = row.shop_id
      this.ruleForm.name = row.name
    }
  },
  mounted () {
    this.get_market()
    this.product_seller_types()
  }
}
</script>

<style lang="less" scoped>
.footers {
  text-align: right;
  margin-top: 10px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}
/deep/ .el-icon-star-on {
  color: red;
  font-size: 10px;
  vertical-align: text-top;
  margin-right: -6px;
}
.item {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.footer {
  text-align: right;
}
</style>
